import React, {PureComponent} from 'react';
import './DashboardNavigationComponent.scss';
import {scroller} from "react-scroll";
import {withRouter} from "react-router";
import logoGertner from './../../../assets/images/icons/logo-gertner-vertikalni.svg';
import {Image} from "react-bootstrap";

class DashboardNavigationComponent extends PureComponent {

    scrollTo = (element) => {
        const elementToScroll = element+'-element';
        scroller.scrollTo(elementToScroll, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    handleLinkToEshop = () => {
        window.open('https://eshop.vinarstvigertner.cz')
    };

    goToHome = () => {
        const { history } = this.props;
        history.push('/');
        window.location.reload();
        window.scrollTo(0,0,);
    };

    render() {
        return (
            <div className='dashboard-navigation-container'>
                <div className='dashboard-navigation-container__image' onClick={this.goToHome}>
                        <Image src={logoGertner}/>
                </div>
                <div className='dashboard-navigation-container__menu'>
                    <ul>
                        <li>
                            <span onClick={() => this.scrollTo('intro')}>O vinařství</span>
                        </li>
                        <li>
                            <span onClick={this.handleLinkToEshop}>Naše vína</span>
                        </li>
                        <li>
                            <span onClick={() => this.scrollTo('events')}>Akce</span>
                        </li>
                        <li>
                            <span onClick={() => this.scrollTo('tasting')}>Degustace</span>
                        </li>
                        <li>
                            <span onClick={() => this.scrollTo('contact')}>Kontakty</span>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(DashboardNavigationComponent);
