import React,{PureComponent} from "react";
import './IntroComponent.scss';
import GertnerButtonComponent from "../../common/GertnerButtonComponent";
import OurPlaceInfo from "./OurPlaceInfo";
import SlideShowComponent from "./slideshow/SlideShowComponent";
import RychardComponent from "./rychard/RychardComponent";
import PhotoBannerComponent from "./photo_banner/PhotoBannerComponent";
import AndZweiComponent from "./an-zwei/AndZweiComponent";
import { scroller } from 'react-scroll';
import {withRouter} from "react-router";
import vinobrani1 from './../../../assets/images/gertner-vinobrani-01.png';
import vinobrani2 from './../../../assets/images/gertner-vinobrani-02.png';
import vinobrani3 from './../../../assets/images/gertner-vinobrani-03.png';
import vinobrani4 from './../../../assets/images/gertner-vinobrani-04.png';


const photoImagesSources = [
    vinobrani1,
    vinobrani2,
    vinobrani3,
    vinobrani4,
];

class IntroComponent extends PureComponent {

    scrollTo = (element) => {
        const elementToScroll = element+'-element';
        scroller.scrollTo(elementToScroll, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    handleLinkToEshop = () => {
        window.open('https://eshop.vinarstvigertner.cz')
    };

    render() {
        return (
            <div className="intro-container">
                    <span className="intro-container__text-title">Život je jízda plná chutí</span>
                    <div className="intro-container__text-description">
                        Naše vína jsou společenská a rozmanitá stejně jako příležitosti, kdy je otevřete.
                        Jsou vypravená do světa tak, abyste je mohli vzít kamkoliv a přivezli s nimi dobrou náladu.
                    </div>
                    <div className='intro-container__btns'>
                            <GertnerButtonComponent
                                text={'Nabídka vín'}
                                handleClick={this.handleLinkToEshop}
                            />
                        <GertnerButtonComponent
                            handleClick={() => this.scrollTo('tasting')}
                            text={'Degustace'}
                        />
                    </div>
                    <div className="intro-container__small_nav">
                        <span onClick={() => this.scrollTo('events')}>Akce</span>
                        <span onClick={() => this.scrollTo('contact')}>Kontakt</span>
                    </div>
                    <div className="intro-container__image" />
                    <OurPlaceInfo />
                    <SlideShowComponent />
                    <RychardComponent />
                    <div className='photo-banner-component'>
                        <PhotoBannerComponent images={photoImagesSources}/>
                    </div>
                    <AndZweiComponent />
            </div>
        )
    }

}

export default withRouter(IntroComponent);
