import React,{Component} from "react";
import HeaderComponent from "./header/HeaderComponent";
import "./UserDashboardComponent.scss";
import IntroComponent from "./intro/IntroComponent";
import TopThreeProductsContainer from "../eshop/product/topthree/TopThreeProductContainer";
import EventDashboardComponent from "./events/EventDashboardComponent";
import { Element } from 'react-scroll';
import TastingComponent from "./tasting/TastingComponent";
import PhotoBannerComponent from "./intro/photo_banner/PhotoBannerComponent";
import ContactComponent from "./contact/ContactComponent";
import DashboardNavigationComponent from "./navigation/DashboardNavigationComponent";
import CartBannerContainer from "../eshop/cart/cart-banner/CartBannerContainer";
import banner1 from './../../assets/images/banner-1.png';
import banner2 from './../../assets/images/banner-2.png';
import banner3 from './../../assets/images/banner-3.png';
import banner4 from './../../assets/images/banner-4.png';

const photoImagesSources = [
    banner1,
    banner2,
    banner3,
    banner4,
];


export default class UserDashboardComponent extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return(
            <div className="user-dashboard-container">
                <HeaderComponent />
                <Element name='intro-element'>
                    <IntroComponent />
                </Element>
                <div className='user-dashboard-container__events'>
                    <Element name='events-element'>
                        <EventDashboardComponent />
                    </Element>
                </div>
                <Element name='tasting-element' className='user-dashboard-container__element'>
                        <TastingComponent />
                </Element>
                <PhotoBannerComponent images = {photoImagesSources} />
                <Element name='contact-element'>
                    <ContactComponent />
                </Element>
                <DashboardNavigationComponent/>
            </div>
        )
    }

}
